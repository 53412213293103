import {brands} from "./Data";
import {Component} from "react";

class Api extends Component {
    getItemUsingID = async (id) => {
        return fetch('https://showcase.animotech.io/product?id=' + id, {
            method: 'GET'
        })
            .then(r => {
                if (r.status !== 401) return r.json();
                else return false
            })
            .then(rJson => {
                if (rJson) {
                    let res = rJson.data;
                    return res.length === 0 ? null : res
                }
                else return false
            })
    };
    searchItems = async ({ category = "", term = "", page = 1 }) => {
        return new Promise((resolve, reject) => {
            let searchParams = new URLSearchParams();
            searchParams.append("page", page);
            console.log('1');
            let categoryId = brands.find(item => item.id === category);
            if (category && category.length > 0) {
                searchParams.append("brand", categoryId.value_ru);
            }
            if (term && term.length > 0) {
                searchParams.append("search", term);
            }
            fetch('https://showcase.animotech.io/products?' + searchParams.toString(), {
                method: 'GET'
            })
                .then(r => r.json())
                .then(rJson => {
                    console.log(rJson.data);
                    
                    let data = rJson.data;
                    let totalLength = rJson.total_rows;
                    console.log(data);
                    return resolve({data, totalLength});
                }).catch(err => {})
        });
    };
    fetchData = async (page, filter, cat, search) => {
        let urlParams = {
            channel: 1364183823,
            page: page? page : 1,
            perPage: 24
        }
        if (search) {
          urlParams.search = search;
        }
        let data = {/*
            filters: [],
            facets_data: facets_data,
            packs_type: "Единичная",
            files:1*/
            facets_data:[{facet_type: "CHECKBOX_GROUP", attr_id: 1345936328 }, {facet_type: "CHECKBOX_GROUP", attr_id: 1345938927}],
            optionalAttrs: [1345936328,1345938927]
            
        }
        if (filter) {
          data.facets_data[0].checked = [filter]
        }
        if (cat) {
          data.facets_data[1].checked = [cat]
        }
        const response = await fetch(
          `https://shop2.animotech.io/products?${new URLSearchParams(urlParams)}`,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
          }
        );
        const responseJson = await response.json();
        if (!response.ok) {
            return;
        }
        return responseJson
    }
    fetchItem = async (id) => {
        const response = await fetch(
          `https://shop2.animotech.io/product?id=`+id,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
          }
        );
        const responseJson = await response.json();
        if (!response.ok) {
            return;
        }
        return responseJson.data
    }
}

export default new Api();
