import React, {Component} from "react";
import Item from "../Item/Item";
import queryString from "query-string";
import Api from "../../Api";
import Paging from "../Paging/Paging";
import {Container, Row, Spinner, Col, Button} from "react-bootstrap"
import ProductsHeader from "../ProductsHeader/ProductsHeader";

import MetaTags from "react-meta-tags";
import PosmItem from "../Item/PosmItem";
import SimpleBar from "simplebar-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";


class PosmList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalItemsCount: null,
            items: [],
            facets: [],
            view: 1,
            selectedFacet: null
        };
        this.updateQueryStr = this.updateQueryStr.bind(this);
        this.toggleView = this.toggleView.bind(this)
    }

    toggleView = (view) => {
        this.setState({
            view: view
        })
    };

    async fetchData() {
        this.setState({loading: true});
        let qsAsObject = queryString.parse(this.props.location.search);
        let page = qsAsObject.page
        let filter = qsAsObject.filter
        let cat = qsAsObject.category;
        let search = qsAsObject.term;
        console.log(decodeURI(cat))
        let results = await Api.fetchData(page? page : 1, filter, cat? decodeURI(cat) : null, search );
        results.data.forEach((item, index) => {
            if (item.files && item.files.length > 0) {
                let ecomImages = item.files.filter(f => f.photo_type === "E-com" && f.type === "Фото");
                if (ecomImages && ecomImages.length > 0) {
                    let mainEcomImage = ecomImages.find(i => i.photo_angle === "Лицевая сторона");
                    if (mainEcomImage && mainEcomImage.url) {
                        results.data[index].image200x200 = mainEcomImage.url + '?wmax=500&hmax=500'
                    }
                }
            }
        });
        if (results?.facets?.[0]?.options) {
            this.setState({ facets: results.facets[0].options });
        }

        this.setState({
            items: results.data,
            loading: false,
            totalItemsCount: results.total_rows
        });
    }

    componentDidMount() {
        this.fetchData();
        let qsAsObject = queryString.parse(this.props.location.search);
        let cat = qsAsObject.category;
        let filter = qsAsObject.filter;

    }

    updateQueryStr(newValues) {
        let current = queryString.parse(this.props.location.search);
        this.props.history.push(window.location.pathname +
            "?" + queryString.stringify({...current, ...newValues})
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let currentQueryStr = queryString.parse(this.props.location.search);
        let oldQueryStr = queryString.parse(prevProps.location.search);


        let areSameObjects = (a, b) => {
            if (Object.keys(a).length !== Object.keys(b).length) return false;
            for (let key in a) {
                if (a[key] !== b[key]) return false;
            }
            return true;
        };

        // We will refetch products only when query string changes.
        if (!areSameObjects(currentQueryStr, oldQueryStr)) {
            this.fetchData();
        }
    }

    render() {
        let parsedQueryStr = queryString.parse(this.props.location.search);
        let category = parsedQueryStr.category;
        let title = category ? "MARS - товары бренда " + category : "MARS - мировые бренды Petcare, Food, Mars Wrigley"
        if (this.state.loading) {
            return (<div style={{
                width: "100%",
                height: "calc(100vh - 180px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}><Spinner style={{color: "#00d7b9"}} animation="border"/></div>);
        }

        return (

            <div style={{width: "100%"}}>
                <MetaTags>
                    <title>{title}</title>
                    <meta name="description" content=""/>
                </MetaTags>
                {/*
                <div style={{display: "flex", flexDirection: "column", position: "fixed", width: 240, left:0, top: 86, height: "calc(100vh - 86px)"}}>
                    <div style={{padding: "5px 10px"}}>
                        <input type="text"
                           value={this.state.searchTerm}
                           onChange={e => {
                               this.setState({ searchTerm: e.target.value });
                           }}
                           onKeyDown={this.keyPress}
                           className="product-search"
                           placeholder="Поиск" />
                    </div>
                    <div style={{flexGrow:1}}>
                    <SimpleBar>
                        <div style={{padding:"5px 10px"}}>
                            <h2 style={{fontSize:16}}>Торговые сети</h2>
                            <div>
                                <input
                                  type="radio"
                                  id={`facetno`}
                                  name="ts"
                                  value={null}
                                  checked={this.state.selectedFacet == null}
                                  onChange={() => {
                                      this.setState({ selectedFacet: null });
                                      this.updateQueryStr({filter: undefined})
                                  }}
                                />
                                <label htmlFor={'facetno'} style={{ marginLeft: 5 }}>Все сети</label>
                            </div>
                            {
                                this.state.facets.map((item, index) => (
                                  <div key={index}>
                                      <input
                                        type="radio"
                                        id={`facet${index}`}
                                        name="ts"
                                        value={item.id}
                                        checked={this.state.selectedFacet === item.id}
                                        onChange={() => {
                                            this.setState({ selectedFacet: item.id });
                                            this.updateQueryStr({filter: item.id})
                                        }}
                                      />
                                      <label htmlFor={`facet${index}`} style={{ marginLeft: 5 }}>{item.text} ({item.count})</label>
                                  </div>
                                ))
                            }
                            
                            
                        </div>
                    </SimpleBar>
                    </div>
                </div>*/}
                <div style={{height: "100%", display: "flex", flexDirection: "column", paddingBottom: 60, paddingLeft:270}}>
                    
                    <Container fluid>
                        <ProductsHeader
                          parsedQueryStr={parsedQueryStr}
                          updateQueryStr={this.updateQueryStr}
                          totalItemsCount={this.state.totalItemsCount}
                          toggleView={this.toggleView}
                          view={this.state.view}
                        />
                        <div className={"row"} style={{padding: "8px 15px", display: "flex", alignItems: "center", gap: "5px", borderBottom: "1px solid rgba(0, 0, 0, 0.1)"}}>
                            <h2 style={{fontSize: 16, margin: 0}}>Торговые сети</h2>
                            <select
                              value={this.state.selectedFacet ?? ''}
                              onChange={(e) => {
                                  const value = e.target.value || null;
                                  this.setState({selectedFacet: value});
                                  this.updateQueryStr({filter: value || undefined});
                              }}
                            >
                                <option value="">Все сети</option>
                                {this.state.facets.map((item, index) => (
                                  <option key={index} value={item.id}>
                                      {item.text}
                                  </option>
                                ))}
                            </select>
                        </div>
                        <Row>
                            <Col xs={12} sm={6}>
                            
                            </Col>
                            <Col xs={12} sm={6}>
                            
                            </Col>
                        </Row>
                        <Row>
                            {this.state.items.map(item => {
                                return <PosmItem view={this.state.view} key={item.id} item={item}/>;
                            })}
                        </Row>
                    </Container>
                    <Paging
                      parsedQueryStr={parsedQueryStr}
                      updateQueryStr={this.updateQueryStr}
                      totalItemsCount={this.state.totalItemsCount}
                    />
                </div>
            </div>
        );
    }
}

export default PosmList;
