import React, { Component } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {brands, dataForTheMenu} from "../../Data";
import {Collapse, Button, ListGroup} from "react-bootstrap";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SimpleBar from 'simplebar-react';
import Api from "../../Api";


const mapStateToProps = state => {
  return {
    showMenu: state.showMenu
  };
};

class ConnectedMenu extends Component {
  state = {
    searchTerm: "",
  };
  constructor(props) {
    super(props);

    this.state = {
      expanded: 0,
      dataForTheMenu,
      searchTerm: "",
      posmTypes: []
    };

    this.renderMenu = this.renderMenu.bind(this);
      this.keyPress = this.keyPress.bind(this);
  }
  toggleExpand =(current) => {
    if (this.state.expanded === current) {
      this.setState({
        expanded:0
      })
    }
    else {
      this.setState({
        expanded:current
      })
    }
  };
  // This method determines from URL whether to highlight a menu item or not
  isMenuItemActive(item, location) {

    if ((location.pathname === "/products" || location.pathname === "/products/") && location.search) {
      let queryStringParsed = queryString.parse(location.search);
      let currentCategory = brands.find(s => s.id === queryStringParsed.category);
      if (!currentCategory) {return false}
      return item.cross_id === currentCategory.id
    }

    return item.url === "/" + location.search;
  }
  keyPress(e) {
    const { searchTerm } = this.state;
    const { pathname } = this.props.location;
    
    if (searchTerm.length > 0 && e.keyCode === 13) {
      // Определяем текущий раздел из pathname
      const section = pathname.includes('/posm') ? 'posm' : 'products';
      
      this.props.history.push(`/${section}?term=${encodeURIComponent(searchTerm)}`);
    }
  }
  componentDidMount() {
    Api.fetchData(1).then(data => this.setState({posmTypes:data.facets[1].options}));
  }

  renderMenu(data) {
    return (
        data.map((x, i) => {
          if (!x.children) {
            return (
                <ListGroup.Item key={x.id}>
                  <a href="# " className={this.isMenuItemActive(x, window.location)? "active" : ""}
                      onClick={() => {
                          this.props.history.push(x.url);this.props.toggleSideBar('close');
                      }}
                  >
                    {x.name}
                  </a>
                </ListGroup.Item>
            );
          }
          else {
            return (
                <div key={x.id}>

                  <Button onClick={()=> {this.toggleExpand(x.id);}}>

                     <span className="mars">MARS</span> {x.name}
                      <FontAwesomeIcon className={x.id === this.state.expanded? "float-right expanded":"float-right"} icon={faChevronRight}/>
                  </Button>

                  <Collapse in={x.id === this.state.expanded}>
                    <ListGroup>
                      {this.renderMenu(x.children)}

                    </ListGroup>
                  </Collapse>
                </div>

            );
          }
        })
    );
  }

  render() {
    if (!this.props.showMenu) return null;
    return (
        <div>
          <input type="text"
                 value={this.state.searchTerm}
                 onChange={e => {
                     this.setState({ searchTerm: e.target.value });
                 }}
                 onKeyDown={this.keyPress}
                 className="product-search"
                 placeholder="Поиск" />
          <SimpleBar>
            <Button style={{color: "rgb(233 167 20)", fontWeight: '500', borderTopWidth: 0}} onClick={() => {
              this.props.history.push('/products');
              this.props.toggleSideBar('close');
            }}>
              Все товары
              <FontAwesomeIcon className="float-right" icon={faChevronRight}/>
            </Button>
            {this.renderMenu(this.state.dataForTheMenu)}
            <div>
              <Button onClick={() => {this.toggleExpand(4);}}>
                <span className="mars">MARS</span> PN POSM
                <FontAwesomeIcon className={4 === this.state.expanded ? "float-right expanded" : "float-right"}
                                 icon={faChevronRight}/>
              </Button>
              <Collapse in={4 === this.state.expanded}>
                <ListGroup>
                  <ListGroup.Item key={"posm0"}>
                    <a onClick={() => {
                         this.props.history.push({
                           pathname: '/posm',
                         })
                       }}
                    >
                      Все категории
                    </a>
                  </ListGroup.Item>
                  {this.state.posmTypes.map((x, i) => {
                    return (
                      <ListGroup.Item key={"posm"+i}>
                        <a className={this.isMenuItemActive(x, window.location)? "active" : ""}
                           onClick={() => {
                             this.props.history.push({
                               pathname: '/posm',
                               search: '?category='+decodeURI(x.id)
                             });
                             
                             this.props.toggleSideBar('close');
                           }}
                        >
                          {x.id}
                        </a>
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Collapse>
            </div>
          </SimpleBar>
        </div>
    );
  }
}

const Menu = withRouter(connect(mapStateToProps)(ConnectedMenu));
export default Menu;
