import React, {Component} from "react";
import Header from "./Components/Header/Header.js";
import ProductList from "./Components/ProductList/ProductList";
import BrandList from "./Components/ProductList/BrandList";
import {Switch, Route} from "react-router-dom";
import Menu from "./Components/Menu/Menu";
import CartDialog from "./Components/CartDialog/CartDialog";
import Details from "./Components/Details/Details";
import Order from "./Components/Order/Order";
import Login from "./Components/Login/Login";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import {Container, Row} from "react-bootstrap"
import Sidebar from "react-sidebar";
import cartImage from "./Images/logo2.png";
import PosmList from "./Components/ProductList/PosmList";
import PosmDetails from "./Components/Details/PosmDetails";

class App extends Component {
    constructor(props) {
        super(props);
        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.state = {
            sidebarOpen: false
        };
    }

    toggleSideBar(param) {
        if (param) {
            if (param === 'close') {
                this.setState({sidebarOpen: false});
            }
        } else {
            this.setState({sidebarOpen: !this.state.sidebarOpen});
        }
    }

    render() {

        const pathname = window.location.pathname;
        return (
            <div>

                <Header toggleSideBar={this.toggleSideBar}/>
                <Sidebar rootClassName="sideBar"
                         sidebar={<div><img className="brand-list-logo" src={cartImage} alt={"Logo"}/><Menu
                             toggleSideBar={this.toggleSideBar}/></div>}
                         shadow={false}
                         children={<></>}
                         open={this.state.sidebarOpen}
                         onSetOpen={this.toggleSideBar}
                         styles={{
                             sidebar: {
                                 zIndex: 1022,
                                 position: "fixed",
                                 top: 0,
                                 width: 290,
                                 bottom: 0,
                                 transition: "transform .3s ease-out",
                                 WebkitTransition: "-webkit-transform .3s ease-out",
                                 willChange: "transform",
                                 overflowY: "auto",
                                 background: "#fff"
                             },
                             overlay: {
                                 zIndex: 1021,
                                 position: "fixed",
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 opacity: 0,
                                 visibility: "hidden",
                                 transition: "opacity .3s ease-out, visibility .3s ease-out",
                                 backgroundColor: "rgba(0,0,0,.45)"
                             }
                         }}
                />
                <Container fluid>
                    <CartDialog/>
                    <Row>
                        {pathname === "/products" || pathname === "/products/" || pathname === "/posm" || pathname === "/posm/" ?
                            <div id="sidebar-wrapper" className="d-none d-lg-block">
                                <Menu toggleSideBar={this.toggleSideBar}/>
                            </div> : null}

                        <div id="page-content-wrapper"
                             className={pathname === "/products" || pathname === "/products/" ? "with-sideBar" : ""}>
                            <Switch>
                                <Route path={'/'} exact component={BrandList}/>
                                <Route path={"/products"} component={ProductList}/>
                                <Route path={"/posm"} component={PosmList}/>
                                <Route path={"/details/:id"} component={Details}/>
                                <Route path={"/posm-details/:id"} component={PosmDetails}/>
                                <ProtectedRoute path={"/order"} component={Order}/>
                                <Route
                                    component={() => (
                                        <div style={{padding: 20}}>Page not found</div>
                                    )}
                                />
                            </Switch>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
    static toggleSideBar() {

    }
}

export default App;
