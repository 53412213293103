import React from "react";
import ReactExport from "react-export-excel-fixed";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export class DetailsExport extends React.Component {

    render() {
        let attributes = this.props.item.attrs.filter(a => a.group_name !== "Параметры ДИКСИ");
        attributes = attributes.filter(a => a.id !== "3477097796");
        attributes = attributes.filter(a => a.id !== "3477096382");
        attributes = attributes.filter(a => a.id !== "3477095978");
        attributes = attributes.filter(a => a.id !== "3477521361");
        attributes = attributes.filter(a => a.id !== "3477096988");
        attributes = attributes.filter(a => a.id !== "3477093405");

        attributes = attributes.filter(a => a.id !== "3477095776");
        attributes = attributes.filter(a => a.id !== "3477093809");
        attributes = attributes.filter(a => a.id !== "3477097190");
        attributes = attributes.filter(a => a.id !== "3477096180");
        attributes = attributes.filter(a => a.id !== "3477097392");
        attributes = attributes.filter(a => a.id !== "3477096584");

        attributes = attributes.filter(a => a.id !== "3477562121");
        attributes = attributes.filter(a => a.id !== "3477096786");
        attributes = attributes.filter(a => a.id !== "3477098200");
        attributes = attributes.filter(a => a.id !== "3477093203");
        attributes = attributes.filter(a => a.id !== "3477093607");
        attributes = attributes.filter(a => a.id !== "3477098973");

        attributes = attributes.filter(a => a.id !== "3477097998");
        attributes = attributes.filter(a => a.id !== "3477098674");
        attributes = attributes.filter(a => a.id !== "3477480064");
        attributes = attributes.filter(a => a.id !== "3477098472");

        let packages = this.props.item.packages;
        let files = this.props.item.files;
        packages.forEach(function(p, index) {
            let lUnits = p.attrs.find(a => a.display_name === "Ед. изм. размера");
            let width = p.attrs.find(a => a.display_name === "Ширина");
            let height = p.attrs.find(a => a.display_name === "Высота");
            let length = p.attrs.find(a => a.display_name === "Глубина");

            let wNettoUnits = p.attrs.find(a => a.display_name === "Ед. изм. веса нетто");
            let wBruttoUnits = p.attrs.find(a => a.display_name === "Ед. изм. веса брутто");
            let weightNetto = p.attrs.find(a => a.display_name === "Вес нетто");
            let weightBrutto = p.attrs.find(a => a.display_name === "Вес брутто");
            let barcode = p.attrs.find(a => a.display_name === "Артикул у Поставщика");
            p.height = (height? height.value + " " : "") + (lUnits && height? lUnits.value : "");
            p.length = (length? length.value + " " : "") + (lUnits && length? lUnits.value : "");
            p.width = (width? width.value + " " : "") + (lUnits && width? lUnits.value : "");
            p.barcode = barcode? barcode.value : "";
            p.netto = (weightNetto? weightNetto.value + " " : "") + (weightNetto && wNettoUnits? wNettoUnits.value : "");
            p.brutto = (weightBrutto? weightBrutto.value + " " : "") + (weightBrutto && wBruttoUnits? wBruttoUnits.value : "");
        });

        return (
            <>
            {(this.props.item.attrs.length > 0) &&
            <ExcelFile filename={"product_details_gtin_" + this.props.item.attrs.find(a => a.display_name !== "gtin") && this.props.item.attrs.find(a => a.display_name !== "gtin").value} element={<Button disabled={!this.props.item}><FontAwesomeIcon icon={faFileExcel}/> Экспорт</Button>}>
                <ExcelSheet data={attributes} name="Атрибуты товра" >
                    <ExcelColumn label="Название атрибута" value="display_name"/>
                    <ExcelColumn label="Значение атрибута" value="value"/>
                </ExcelSheet>
                <ExcelSheet data={packages} name="ВГХ" >
                    <ExcelColumn label="Тип " value="type"/>
                    <ExcelColumn label="Количество едениц товара" value="items_inside"/>
                    <ExcelColumn label="Ширина" value="width"/>
                    <ExcelColumn label="Высота" value="height"/>
                    <ExcelColumn label="Глубина" value="length"/>
                    <ExcelColumn label="Вес нетто" value="netto"/>
                    <ExcelColumn label="Вес брутто" value="brutto"/>
                    <ExcelColumn label="Артикул у поставщика" value="barcode"/>
                </ExcelSheet>
                <ExcelSheet data={files} name="Фото и документы" >
                    <ExcelColumn label="Название" value="display_name"/>
                    <ExcelColumn label="Тип" value="type"/>
                    <ExcelColumn label="Ссылка" value="url"/>
                </ExcelSheet>
            </ExcelFile>
            }
                </>
        );
    }
}
